import { Component, Input, OnDestroy, OnInit, output, OutputEmitterRef } from '@angular/core';
import { ActivityService, LabelService, LocalStorageService, RequestService, SearchService, UserPromptsService, UserService, UtilitiesService } from '@core/services';
import { Subscription } from 'rxjs';
import { Request, Role, Service, User } from '@models/model';
import { IDataTableConfig } from '../data-table/data-table.component';
import { keys, orderBy, uniq, uniqBy } from 'lodash';
import { take } from 'rxjs/operators';
import { IObjectMap } from '@models/interface';
import { ActivatedRoute, Router } from '@angular/router';
import { isValid } from 'date-fns';
import { PageEvent } from '@angular/material/paginator';
import { NewRequestFormComponent } from '@shared/entry-components/new-request-form/new-request-form.component';

export interface RequestTabFilters {
  textFilter?: string;
  limit?: number
}

@Component({
  selector: 'app-customer-request-tab',
  templateUrl: './customer-request-tab.component.html',
  styleUrl: './customer-request-tab.component.scss'
})
export class CustomerRequestTabComponent implements OnInit, OnDestroy {

  @Input({ required: true }) user: User;
  public selectedTabChange: OutputEmitterRef<number> = output();
  
  public labels: any = {};
  protected isLoading = true;
  private listSubscription: Subscription;
  public paginationOptions = this.resetPaginatorOptions();
  protected requests: Request[];
  private services: Service[] = [];
  public usersMap: IObjectMap<User> = {};
  public currentRequestId: string;
  public tableCfg: IDataTableConfig;
  private count: number;
  public creatorName: string;
  public currentUserConnectedServices: string[];
  protected requestTabFilters: RequestTabFilters = {};
  protected userViewMode: 'customerTab' = 'customerTab';
  public orgId = this.localStorageService.getItemSync('user_organization');
  public userRole: Role;
  
  constructor(
    private requestService: RequestService,
    private labelService: LabelService,
    private userService: UserService,
    private route: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private searchService: SearchService,
    private activityService: ActivityService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userPromptService: UserPromptsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userRole = this.userService.getCurrentUserRole();
    const [labels, param, res] = await Promise.all([
      this.labelService.getLabels('app-customer-request-tab'),
      this.route.params.pipe(take(1)).toPromise(),
      this.activityService.getUserConnectedWorkingAreasAndServices()
    ])
    this.labels = labels.data;
    this.currentRequestId = param.requestId;
    this.currentUserConnectedServices = res[keys(res)[0]].userConnectedServices;
    this.fetchRequestsForFirstLoad();
    this.getCountsForUser();
  }

  private async getCountsForUser() {
    this.requestService.getCountForUser(this.user.id, this.userViewMode).then(count => {
      this.count = count;
      this.paginationOptions.totalSize = this.count
    })
  }

  private fetchRequestsForFirstLoad() {
    this.isLoading = true;
    this.requestTabFilters.limit = 5;
    this.listSubscription = this.requestService.getCustomerOrExecutorRequestsForUser(this.user.id, this.userViewMode, this.requestTabFilters.limit).subscribe(async requests => {
      this.requests = requests;
      this.paginationOptions.lastRef = this.requests[this.requests.length - 1]?.['__doc'];
      this.paginationOptions.firstRef = this.requests[0]?.['__doc'];
      this.paginationOptions.dataPerPage = this.requestTabFilters.limit;
      await this.getOtherData(requests);
      this.loadData(0, this.requestTabFilters.limit);
    });
  }

  private async getOtherData(request: Request[]) {
    await this.getServices(request);
    await this.fetchUsers(request);
  }

  private async getServices(requests: Request[]) {
    const requestsServiesId = requests.filter(req => !this.services.find(s => s.id == req.management.serviceId)).map(req => req.management.serviceId);
    if (requestsServiesId.length) this.services.push(...(await this.userService.getUserServices(uniq(requestsServiesId))).filter(s => !!s));
  }

  private async fetchUsers(requests: Request[]) {
    const executorIds: string[] = requests.filter(req => !this.usersMap[req.management.executorId])
      .map((request: Request) => request.management.executorId);
    if (executorIds.length) {
      const users = await this.userService.getUsersFromIds(uniq(executorIds)).pipe(take(1)).toPromise();
      this.usersMap = {};
      users.forEach((user: User) => {
        if (user) {
          this.usersMap[user.id] = user;
        }
      });
    }
  }

  private loadData(from?: number, to?: number) {
    this.updateTableData(this.requests.filter(data => !!data).slice(from, to));
  }

  private getTableData(requests: Request[]) {
    const data = [];
    requests.forEach((request: Request) => {
      const service = this.services.find(service => service.id == request.management.serviceId);
      
      const executor: User = this.usersMap[request.management.executorId];
      data.push({
        _rowClass: `status-${request.status}`,
        title: request.id === this.currentRequestId ? `${request.title} - ${this.labels.current_request}` : request.title,
        executorFullName: executor ? executor.fullname : '',
        service: service?.name || '',
        originalRequest: request,
      })
    })
    return data;
  }

  private async updateTableData(requests: Request[]) {
    const config: IDataTableConfig = {
      data: this.getTableData(requests),
      displayProperties: ['title', 'service', 'executorFullName'],
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
    }
    this.tableCfg = config;
    this.isLoading = false;
  }

  private resetPaginatorOptions() {
    return {
      totalSize: this.count,
      dataPerPage: 5,
      pageIndex: 0,
      lastRef: null,
      firstRef: null
    };
  }

  public async getRequestCreatorName(creatorId: string) {
    this.creatorName = creatorId ? this.usersMap[creatorId] ? this.usersMap[creatorId].fullname : (await this.userService.getUserById(creatorId).pipe(take(1)).toPromise())?.fullname : '';
  }

  public toDate(d: any, skipYear?: boolean) {
    const date = new Date(d);

    if (isValid(date)) {
      return skipYear ? this.utilitiesService.getLocaleDateString(date) : this.utilitiesService.getLocaleDateWithYear(date)
    } else {
      return '';
    }
  }

  protected handleRowSelect(row: any) {
    const request = row.originalRequest as Request;
    if (this.currentUserConnectedServices.includes(request?.management?.serviceId)) {
      if (this.currentRequestId) {
        setTimeout(() => {
          this.selectedTabChange.emit(0);
        }, 500);
      }

      this.router.navigate([`/${this.orgId}/dashboard/requests/detail`, request.id]);
    } else {
      this.userPromptService.showAlertDialogue(this.labels.not_connected_service_title, this.labels.you_are_not_connected_to_this_service);
    }
  }

  public onPaginationChange(evt: PageEvent): void {
    if (this.requestTabFilters.textFilter && this.requests.length > 0) {
      const newFromIndex = evt.pageIndex * evt.pageSize;
      const newToIndex = (evt.pageIndex + 1) * evt.pageSize;
      this.paginationOptions.dataPerPage = evt.pageSize;
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.loadData(newFromIndex, newToIndex);
    } else {
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.paginationOptions.dataPerPage = evt.pageSize;
      this.fetchRequestsUsingFilter(evt.previousPageIndex <= evt.pageIndex, evt.pageIndex == evt.previousPageIndex); //= is for resize of page count scenario
    }
  }

  public handleFilterSubmit() {
    this.listSubscription?.unsubscribe();
    this.requests = [];
    this.updateTableData([]);
    this.paginationOptions = this.resetPaginatorOptions();
    this.fetchRequestsUsingFilter(true, false);
  }

  protected clearSearch() {
    this.requestTabFilters.textFilter = '';
    this.fetchRequestsForFirstLoad();
    this.getCountsForUser();
  }

  private async fetchRequestsUsingFilter(isForwardNavigation: boolean, isReloadedContext: boolean) {
    this.isLoading = true;
    const opts = this.paginationOptions;

    const fromIndex = opts.pageIndex * opts.dataPerPage;
    const toIndex = (opts.pageIndex + 1) * opts.dataPerPage;

    if (this.requestTabFilters.textFilter) {
      this.requestService.searchRequestsForUserTab(this.user.id, this.requestTabFilters, this.userViewMode).subscribe(async requests => {
        await this.getOtherData(requests);
        const filteredRequests: any[] = this.searchService.searchListBySearchIndex(requests, this.requestTabFilters.textFilter); //returns based on relevancy
        this.requests = this.orderRequests(uniqBy(filteredRequests.concat(requests), 'id'));
        this.paginationOptions.totalSize = this.requests.length;
        this.loadData(fromIndex, toIndex)
      })
    } else {
      await this.getCountsForUser();
      this.listSubscription?.unsubscribe();

      if (isForwardNavigation) {
        this.listSubscription = this.requestService
          .getRequestsByFiltersForUserTabs(this.user.id, this.userViewMode, opts.dataPerPage,
            isReloadedContext ? this.paginationOptions.firstRef : this.paginationOptions.lastRef, isForwardNavigation, isReloadedContext)
          .subscribe(async requests => {
            if (!isReloadedContext) {
              this.requests = [];
            }
            if (requests?.length) {
              await this.getOtherData(requests);
              this.requests = this.orderRequests(requests);
              this.paginationOptions.lastRef = (this.requests[this.requests.length - 1] as any)['__doc'];
              this.paginationOptions.firstRef = (this.requests[0] as any)['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.updateTableData(this.requests);
            }
          });
      } else {
        this.listSubscription = this.requestService
          .getRequestsByFiltersForUserTabs(this.user.id, this.userViewMode, opts.dataPerPage, this.paginationOptions.firstRef, isForwardNavigation, false)
          .subscribe(requests => {
            this.requests = [];
            if (requests?.length) {
              this.requests = this.orderRequests(requests);
              this.paginationOptions.lastRef = (this.requests[this.requests.length - 1] as any)['__doc'];
              this.paginationOptions.firstRef = (this.requests[0] as any)['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.updateTableData(this.requests);
            }
          });
      }
    }
  }

  private orderRequests(requests: Request[]) {
    return orderBy(requests, ['countMatch', 'status'], ['desc', 'asc'])
  }

  public createRequest(): void {
    this.userPromptService.showDialogue(NewRequestFormComponent, {
      customer: this.user
    }, null, false);
  }

  ngOnDestroy(): void {
    this.listSubscription.unsubscribe();
  }
}
