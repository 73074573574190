import { Injectable } from '@angular/core';
import { take, map, mergeAll } from 'rxjs/operators';
import { Observable, from, Observer } from 'rxjs';
import { each, upperFirst, words, endsWith, findIndex, keys, uniqBy, values, isEmpty, cloneDeep } from 'lodash';
import { FirestoreService, DocItem, QueryFN } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import {
  Role,
  User,
  Note,
  AttachedDoc,
  Activity,
  ActivityReply,
  UserData,
  defaultUserAvailability,
  WorkingArea,
  Service,
  UserRolesConfig,
  AfasDetails
} from '@shared/model';
import { SearchService } from './search.service';
import { RoleTypes } from '@shared/enum';
import { IObjectMap } from '@shared/interface';
import { ApiService } from './api.service';
import { ObjectMap } from '../../../../functions/src/interfaces';
import { RolesDialogComponent } from '@shared/entry-components/roles/roles.component';
import { UserPromptsService } from './user-prompt.service';
import { Router } from '@angular/router';
import { isValid } from 'date-fns';
import { AuthService } from '@core/services/auth.service';
import { TimestampDate } from 'timestamp-date';
import { WorkingAreaService } from './workingarea.service';
import { OrgServiceService } from './org-service.service';
import { UserFilter } from '@users/components';
import { Where } from '@shared/model/firestore';
import { LabelService } from './labels.service';
import { OrganizationService } from './organization.service';
import { ApiCallType } from '@models/model/api.call';
// import { CloudFunctionService } from './functions.service';

type DocumentReference = firebase.default.firestore.DocumentReference;
type UserActionRoleTypes = 'executor' | 'coordinator' | 'professional' | 'institutionContact' | 'customer' | 'servicePoint';
export type UserRoleActionTypes = 'add' | 'remove' | 'removeService' | 'update';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private NAME_PREFIXES = ['van', 'van de', 'ter', 'van der', 'de', 'ten'];
  private orgId: string;
  private timestampDate = new TimestampDate();

  constructor(
    private localStorageService: LocalStorageService,
    private searchService: SearchService,
    // private cloudFunctionService: CloudFunctionService,
    private afsDB: FirestoreService,
    private apiService: ApiService,
    private userPromptsService: UserPromptsService,
    private router: Router,
    private authService: AuthService,
    private workingAreaService: WorkingAreaService,
    private orgServiceService: OrgServiceService,
    private labelService: LabelService,
    private orgganizationService: OrganizationService
  ) {
    this.localStorageService.getItem('user_organization').subscribe(res => {
      this.orgId = res;
    });
  }

  public getCurrentUserId(): string {
    return this.localStorageService.getItemSync('user_id');
  }

  public getCurrentUser(): Observable<User> {
    const userId: string = this.localStorageService.getItemSync('user_id');

    return this.getUserById(userId);
  }

  public getCurrentUserData(): Observable<UserData> {
    return this.getCurrentUser().pipe(
      map(user => {
        if (user) return this.afsDB.docWithId$(`userData/${user?.uid}`);
      }),
      mergeAll()
    ) as Observable<UserData>;
  }

  public getCurrentUserRole(): Role {
    try {
      return JSON.parse(this.localStorageService.getItemSync('user_role'));
    } catch (e) {
      throw Error(JSON.stringify({ error: e, info: 'Error while getting user_role in storage' }));
    }
  }

  public handleUserExpirySync(userId: string): Promise<void> {
    return this.apiService.post<void>(`users/${this.getCurrentUserId()}/roles-change/side-effects`, { userId })
      .pipe(
        map(res => {
          if (!res.success) {
            console.log(res);
          }

          return res.success ? res.data : null;
        }),
        take(1)
      ).toPromise();
  }

  public getExpiringUsers(): Observable<User[]> {
    return this.afsDB.colWithIds$(`organizations/${this.orgId}/users`, (refV) => {
      const ref = refV.where('expiryDate', '>=', new Date(0))
        .orderBy('expiryDate', 'asc');

      return ref.limit(50);
    });
  }

  public getUserByEmail(email: string, orgId?: string): Promise<User> {
    return new Promise(resolve => {
      if (email) {
        this.afsDB
          .colWithIds$(`organizations/${orgId || this.orgId}/users`, ref => {
            return ref.where('email', '==', email).limit(1);
          })
          .pipe(take(1))
          .subscribe((res: User[]) => {
            resolve(res.length > 0 ? res[0] : null);
          });
      } else {
        resolve(null);
      }
    });
  }

  public async askUserRole(roles?: Role[], canClose?: boolean): Promise<Role> {
    return new Promise(resolve => {
      const currentRole = this.getCurrentUserRole();

      this.userPromptsService.showDialogue(
        RolesDialogComponent,
        { roles, currentRole, canClose },
        (res: Role) => {
          if (res) {
            this.localStorageService.setItem('user_role', JSON.stringify(res));
            const orgName = this.localStorageService.getItemSync(
              'user_organization'
            );

            const route = this.getDefaultRoute(res.roleType);
            this.router.navigateByUrl(`/${orgName}/${route}`);
          }

          resolve(res);
        }, true
      );
    });
  }

  public updateAuthClaims(): Promise<void> {
    return this.apiService.post<void>('users/claims/update', {})
      .pipe(
        map(res => {
          if (!res.success) {
            console.error(res);
          }

          return res.success ? this.authService.propagateCustomClaims() : Promise.resolve();
        }),
        mergeAll(),
        take(1)
      ).toPromise();
  }

  public updateAuthUserDetails(payload: ObjectMap<any>): Promise<User> {
    return this.apiService.post<User>('users/user_auth', payload)
      .pipe(
        map(res => {
          if (!res.success) {
            console.log(res);
          }

          return res.success ? res.data : null;
        }),
        take(1)
      ).toPromise();
  }

  public getUserByFirstNameAndPhone(
    firstname: string,
    phone: string
  ): Promise<User> {
    return new Promise(resolve => {
      this.afsDB
        .colWithIds$(`organizations/${this.orgId}/users`, ref => {
          return ref
            .where('firstname', '==', firstname)
            .where('phone', '==', phone)
            .limit(1);
        })
        .pipe(take(1))
        .subscribe((res: User[]) => {
          resolve(res[0]);
        });
    });
  }

  public getUserByFirstNameAndBirthday(
    firstname: string,
    birthday: string
  ): Promise<User> {
    return new Promise(resolve => {
      this.afsDB
        .colWithIds$(`organizations/${this.orgId}/users`, ref => {
          return ref
            .where('firstname', '==', firstname)
            .where('birthday', '==', birthday)
            .limit(1);
        })
        .pipe(take(1))
        .subscribe((res: User[]) => {
          resolve(res[0]);
        });
    });
  }

  public getUserById(userId: string): Observable<User> {
    const docRef = `/organizations/${this.orgId}/users/${userId}`;
    return this.afsDB.docWithId$(docRef);
  }

  public getUsersAndRefsInOrganization(queryFn?: any): Observable<User[]> {
    const ref = `/organizations/${this.orgId}/users`;
    return this.afsDB.colWithIds$(ref, queryFn, true);
  }

  public getReservationsCoordinators(): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;
    return this.afsDB.colWithIds$(colRef, ref => {
      return ref.where('moduleReservations.coordinator', '==', true);
    });
  }

  private queryUsersCol(queryFn?: any): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;
    return this.afsDB.colWithIds$(colRef, queryFn);
  }

  public getUsersMatchingName(firstname: string, lastname: string): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
      return ref.where('firstname', '==', firstname)
        .where('lastname', '==', lastname);
    }));
  }

  public getUsersForOrgAdmin(limit: number, lastRef?: any): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
      ref = ref.orderBy('lastname', 'asc');

      if (lastRef) {
        ref = ref.startAfter(lastRef);
      }

      return ref.limit(limit);
    }, true));
  }

  public getUsersMatchingAddress(postalcode: string, houseNumber: number): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
      return ref.where('address.number', '==', houseNumber)
        .where('address.postalcode', '==', postalcode);
    }));
  }

  public getUsers(searchWord?: string, limit?: number, modulePath?: string, lastRef?: any): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    if (searchWord) {
      return new Observable((observer: Observer<User[]>) => {
        const where = [['active', '==', true]];

        if (modulePath) {
          where.push([modulePath, '==', true]);
        }

        this.searchService.searchCollectionIndex({
          searchText: searchWord,
          ref: colRef,
          limit: limit || 50,
          where
        }).then((users: User[]) => {
          observer.next(users);
        });
      });
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        if (modulePath) {
          ref = ref.where(modulePath, '==', true);
        }

        if (lastRef) {
          ref = ref.startAfter(lastRef);
        }

        return ref
          .where('active', '==', true)
          .orderBy('log.updatedAt', 'desc')
          .limit(limit || 30);
      }, true);
    }
  }

  public getUsersWithPagination(perPage: number, lastRef?: any): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return this.afsDB.colWithIds$(colRef, ref => {
      ref = ref
        .where('active', '==', true)
        .orderBy('lastname', 'asc')
        .limit(perPage || 30);

      if (lastRef) {
        ref = ref.startAfter(lastRef);
      }

      return ref;
    }, true);
  }

  public getUsersForNetworkPartner(kind: string): Observable<User[]> {
    return this.queryUsersCol(ref => {
      return ref.where('networkPartner.networkPartnerType', '==', kind);
    });
  }

  public getCoaches(searchWord?: string, limit?: number): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    if (searchWord) {
      return new Observable(observer => {
        this.searchService.searchCollectionIndex<User>({
          searchText: searchWord,
          ref: colRef,
          limit: limit || 25,
          where: [['active', '==', true], ['isCoach', '==', true]]
        }).then(users => {
          observer.next(users);
        });
      });
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        return ref
          .where('active', '==', true)
          .where('isCoach', '==', true)
          .limit(limit || 25)
          .orderBy('log.updatedAt', 'desc');
      });
    }
  }

  public async saveUser(user: User): Promise<DocumentReference> {
    const newUser: User = JSON.parse(JSON.stringify(user));

    newUser.firstname = newUser.firstname.trim();
    newUser.lastname = newUser.lastname.trim();
    newUser.fullname = `${user.firstname} ${user.lastname}`;

    if (newUser.email) {
      newUser.email = newUser.email.toLowerCase().trim();
    }

    newUser.availability = newUser.availability || defaultUserAvailability();

    return this.afsDB.add(`organizations/${this.orgId}/users`, newUser);
  }

  public async updateUser(user: User, orgId?: string): Promise<any> {


    delete user['__doc'];

    let newUser: User = JSON.parse(JSON.stringify(user));
    newUser = this.timestampDate.parseStringToDate(newUser);

    newUser.firstname = newUser.firstname.trim();
    newUser.lastname = newUser.lastname.trim();
    newUser.fullname = `${user.firstname} ${user.lastname}`;
    newUser.searchIndex = new User(user).searchIndex;


    if (newUser.email) {
      newUser.email = newUser.email.toLowerCase().trim();
    }


    // @Todo: I think birthday should be a Date type
    if (!isValid(new Date(newUser.birthday))) {
      newUser.birthday = '';
    }

    newUser.availability = newUser.availability || defaultUserAvailability();

    return this.afsDB.update(
      `organizations/${orgId || this.orgId}/users/${user.id}`,
      newUser
    );
  }

  // private configFullName(user: User): string {
  //   if (user.lastname.toLowerCase() === user.userPartner.lastname.toLowerCase()) {
  //     return `${user.firstname} - ${user.userPartner.firstname} ${user.lastname}`;
  //   }
  //   return `${user.firstname} ${user.lastname} - ${user.userPartner.firstname} ${user.userPartner.lastname}`;
  // }

  public getDefaultRoute(roleType: RoleTypes) {
    const routesMap: IObjectMap<string> = {
      [RoleTypes.orgAdmin]: 'dashboard/workingareas/list',
      [RoleTypes.professional]: 'dashboard/requests',
      [RoleTypes.coordinator]: 'dashboard/requests',
      [RoleTypes.servicePoint]: 'dashboard/requests',
      [RoleTypes.excecutor]: 'dashboard/requests/volunteer',
      [RoleTypes.user]: 'dashboard',
      [RoleTypes.networkPartner]: 'dashboard/service-registration',
      [RoleTypes.coordinatorReservations]: 'dashboard/inventory/reservations',
      [RoleTypes.institutionContact]: 'dashboard/events/list-management',
      [RoleTypes.customer]: 'dashboard/events/list'
    };

    return routesMap[roleType];
  }

  public getUsersFromIds(userIds: string[]): Observable<User[]> {
    return this.afsDB.docsFromId$(
      `/organizations/${this.orgId}/users`,
      userIds
    );
  }

  public getUsersFromIdsNew(userIds: string[]): Observable<User[]> {
    return this.afsDB.docsFromIdNew$(`/organizations/${this.orgId}/users`, userIds);
  }

  public getUsersInCurrentOrg(queryFn?: QueryFN): Observable<User[]> {
    return this.afsDB.colWithIds$(`/organizations/${this.orgId}/users`, queryFn);
  }

  public async archiveUser(user): Promise<any> {
    user.active = false;
    return this.updateUser(user);
  }

  public getUserNotes(userId: string): Observable<Note[]> {
    const colRef = `/organizations/${this.orgId}/users/${userId}/notes`;
    return this.afsDB.colWithIds$(colRef, ref => {
      return ref.orderBy('log.createdAt', 'desc');
    });
  }

  public addNote(note: Note, userId: string): Promise<any> {
    const colRef = `/organizations/${this.orgId}/users/${userId}/notes`;
    return this.afsDB.add(colRef, note);
  }

  public updateNote(note: Note, userId: string): Promise<any> {
    const docRef = `/organizations/${this.orgId}/users/${userId}/notes/${note.id}`;
    return this.afsDB.update(docRef, note as DocItem);
  }

  public deleteNote(noteId: string, userId: string): Promise<any> {
    const docRef = `/organizations/${this.orgId
      }/users/${userId}/notes/${noteId}`;
    return this.afsDB.doc(docRef).delete();
  }

  public getUserDocs(userId: string, limit?: number): Observable<AttachedDoc[]> {
    const colRef = `/organizations/${this.orgId}/users/${userId}/docs`;

    return this.afsDB.colWithIds$(colRef, ref => {
      ref = ref.where(`isCustomerDoc`, '==', true)
        .orderBy('log.createdAt', 'desc');

      if (limit) ref = ref.limit(limit);
      return ref;
    });
  }

  public getEmployeeDocs(userId: string, limit?: number): Observable<AttachedDoc[]> {
    const colRef = `/organizations/${this.orgId}/users/${userId}/docs`;

    return this.afsDB.colWithIds$(colRef, ref => {
      ref = ref.where(`isEmployeeDoc`, '==', true)
        .orderBy('log.createdAt', 'desc');

      if (limit) ref = ref.limit(limit);
      return ref;
    });
  }

  public addDoc(doc: AttachedDoc, userId: string): Promise<any> {
    const colRef = `/organizations/${this.orgId}/users/${userId}/docs`;
    return this.afsDB.add(colRef, doc);
  }

  public deleteDoc(docId: string, userId: string): Promise<any> {
    const docRef = `/organizations/${this.orgId}/users/${userId}/docs/${docId}`;
    return this.afsDB.doc(docRef).delete();
  }

  public getActivity(userId: string, activityId: string): Observable<Activity> {
    const docRef = `/organizations/${this.orgId}/users/${userId}/activities/${activityId}`;
    return from(this.afsDB.docWithId$<Activity>(docRef));
  }

  public updateActivityReply(reply: ActivityReply, activity: Activity, userId: string): Promise<any> {
    if (reply.id) {
      // update action
      const index = findIndex(activity.replies, { id: reply.id });

      if (index < 0) {
        throw Error('Reply not found in activity');
      }

      reply.log.updatedAt = new Date(); // this.afsDB.getServerTime(),
      reply.log.modifiedBy = this.localStorageService.getItemSync('user_id');

      activity.replies[index] = reply;
    } else {
      // create action
      reply.id = this.afsDB.getNewId();

      reply.log = {
        createdAt: new Date(), // this.afsDB.getServerTime(),
        createdBy: this.localStorageService.getItemSync('user_id'),
        updatedAt: new Date(), // this.afsDB.getServerTime(),
        modifiedBy: this.localStorageService.getItemSync('user_id')
      };

      activity.replies.push(reply);
    }

    const docRef = `/organizations/${this.orgId}/users/${userId}/activities/${activity.id}`;
    return this.afsDB.update(docRef, activity as DocItem);
  }

  public addActivityToUser(userId: string, activity: Activity): Promise<void> {
    activity.id = activity.id || this.afsDB.getNewId();

    const docRef = `/organizations/${this.orgId}/users/${userId}/activities/${activity.id}`;
    return this.afsDB.setDoc(docRef, activity as DocItem);
  }

  public isUserNetworkPartner(userId: string): Observable<User> {
    return this.getUserById(userId).pipe(
      map(user => {
        if (user && user.networkPartner && user.networkPartner.isNetworkPartner) {
          return user;
        } else {
          return null;
        }
      })
    );
  }

  public deleteActivityFromUser(userId: string, activityId: string): Promise<void> {
    const docRef = `/organizations/${this.orgId}/users/${userId}/activities/${activityId}`;
    return this.afsDB.doc(docRef).delete();
  }

  public deleteUser(userId: string): Promise<any> {
    // const docRef = `/organizations/${this.orgId}/users/${userId}`;
    // return this.afsDB.doc(docRef).delete();
    return this.apiService.get(`orgs/${this.orgId}/users/${userId}/user-deletion`).pipe(take(1)).toPromise();
  }

  public updateActivityInUser(userId: string, activity: Activity): Promise<void> {
    const docRef = `/organizations/${this.orgId}/users/${userId}/activities/${activity.id}`;
    return this.afsDB.update(docRef, activity as DocItem);
  }

  public formatUserName(userName: string): string {
    let outpгtUserName = userName;
    const userWords = words(userName);
    if (userWords.length === 1) {
      outpгtUserName = upperFirst(userName);
    } else {
      const first = (userWords[0] || '').toLowerCase();
      if (userWords.length === 2) {
        each(this.NAME_PREFIXES, prefix => {
          if (prefix === first) {
            outpгtUserName = this.formatUserParts(userName, prefix, userWords, 1);
            return false;
          }
        });
      } else if (userWords.length > 2) {
        const two = first + ' ' + (userWords[1] || '').toLowerCase();
        each(this.NAME_PREFIXES, prefix => {
          if (prefix === two) {
            outpгtUserName = this.formatUserParts(userName, prefix, userWords, 2);
            return false;
          }
        });
      }
    }

    return outpгtUserName;
  }

  private formatUserParts(inputUserName: string, prefix: string, wordsToChange: string[], shiftCount: number): string {
    let count = 0;
    while (count < shiftCount) {
      wordsToChange.shift();
      count++;
    }
    wordsToChange[0] = upperFirst(wordsToChange[0]);
    wordsToChange.unshift(prefix);
    const name = wordsToChange.join(' ');
    if (endsWith(inputUserName, ' ')) {
      return name + ' ';
    }
    return name;
  }

  private async saveUserToAfas(orgId: string, user: User) {
    const labels = (await this.labelService.getLabels('user-service')).data;

    if (!user.afasDetails?.connected && (!user.address?.street || !user.gender || !user.birthday || !user.email)) {
      this.userPromptsService.showAlertDialogue(labels.afas_role_update_warning_title, labels._afas_warning_message);
      return false;
    }

    try {
      if (user.afasDetails?.connected) {
        user.afasDetails.isAfasExecutor = true;
        await this.updateAfasUserRoleAsExecutor(orgId, user, 'set user as executor');
      } else {
        await this.afasConnect(orgId, user);
        user.afasDetails.isAfasExecutor = true;
        await this.updateAfasUserRoleAsExecutor(orgId, user, 'set user as executor');
      }
      return true;
    } catch (error) {
      this.userPromptsService.showToast('Error while saving user to AFAS');
      throw error;
    }
  }

  public async updateUserRoles(user: User, role: UserActionRoleTypes, workingAreaId: string, action: UserRoleActionTypes, serviceId?: string, save?: boolean, institutionId?: string, prevValue?: { w?: string; s?: string, type?: 'open' | 'close' }): Promise<any> {

    const roles: UserRolesConfig = Object.assign({}, user.roles);
    if (action === 'add') {
      roles.workingAreas[workingAreaId] = roles?.workingAreas?.[workingAreaId] || {};
      roles.workingAreas[workingAreaId][role] = { active: true };

      if (serviceId) {
        roles.services[serviceId] = roles.services[serviceId] || {};
        roles.services[serviceId][role] = { active: true };

        if (role === 'executor') {
          roles.services[serviceId][role].disabled = false;
          const org = await this.orgganizationService.getCurrentOrganization().pipe(take(1)).toPromise();
          if (org.settings.accountingSystem && org.settings.accountingSystem.id == 'afas' && org.settings.accountingSystem.afas.autoCreateExecutors) {
            const res = await this.saveUserToAfas(org.id, user);
            if (!res) return false;
          }
        }
      }
      if (role === 'institutionContact') {
        user.roles.isInstitutionContact = true;
        roles.institutions = roles?.institutions || {} as any;
        roles.institutions[institutionId] = { institutionId: institutionId };
      }
      if (role == 'customer') {
        await this.modifyRoleViaRequest(user, roles, prevValue);
        roles.isCustomer = true;
        roles.services[serviceId].customer = { active: true, disabled: false };
        roles.workingAreas[workingAreaId].customer.active = true;
      }
      if (role == 'servicePoint') {
        roles.workingAreas[workingAreaId][role] = { active: true };
      }
    } else if (action === 'removeService') {
      delete roles.services[serviceId];

    } else if (action == 'update') {
      if (prevValue.type == 'close') {
        await this.modifyRoleViaRequest(user, roles, prevValue);
      } else {
        roles.isCustomer = true;
        roles.services[serviceId].customer = { active: true };
        roles.workingAreas[workingAreaId].customer = { active: true };
      }
    } else {

      delete roles.workingAreas[workingAreaId]?.[role];

      if (values(roles.workingAreas[workingAreaId]).length < 1) {
        delete roles.workingAreas[workingAreaId];
      }

      if (serviceId) {
        delete roles.services[serviceId]?.[role];

        if (values(roles.services[serviceId]).length < 1) {
          delete roles.services[serviceId];
        }
      }
      if (role === 'institutionContact') delete user.roles.institutions[institutionId];
    }

    roles.isExecutor = keys(roles.services).filter(sId => roles.services[sId].executor).length > 0;
    roles.isCoordinator = keys(roles.services).filter(sId => roles.services[sId].coordinator).length > 0;
    roles.isProfessional = keys(roles.workingAreas).filter(wId => roles.workingAreas[wId].professional).length > 0;
    roles.isInstitutionContact = keys(roles.institutions).filter(iId => roles.institutions[iId]).length > 0;
    roles.isServicePoint = keys(roles.workingAreas).filter(iId => {
      return roles.workingAreas[iId]?.servicePoint?.active
    }).length > 0;

    if (!roles.isExecutor && user.afasDetails && user.afasDetails.isAfasExecutor) {
      const org = await this.orgganizationService.getCurrentOrganization().pipe(take(1)).toPromise();
      const afasSettings = org.settings.accountingSystem.afas;

      if (org.settings.accountingSystem.id == 'afas' && afasSettings.autoCreateExecutors) {
        user.afasDetails.isAfasExecutor = false;
        user = await this.updateAfasUserRoleAsExecutor(this.orgId, user, 'unset user as executor');
      }
    }

    user.roles = roles;

    if (save) {
      return this.updateUser(user).then(() => true).catch(() => false);
    } else {
      return Promise.resolve(true);
    }
  }

  async modifyRoleViaRequest(customer: User, roles: UserRolesConfig, prevValue: { w?: string; s?: string }) {
    if (prevValue?.s || prevValue?.w) {
      const querys: Promise<number>[] = [];
      if (prevValue?.s) {
        querys.push(this.getCustomerCount(customer.id, { field: 'serviceId', value: prevValue.s }, false),
          this.getCustomerCount(customer.id, { field: 'serviceId', value: prevValue.s }, true)
        )
      } else {
        querys.push(this.getCustomerCount(customer.id, { field: 'workareaId', value: prevValue.w }, false),
          this.getCustomerCount(customer.id, { field: 'workareaId', value: prevValue.w }, true)
        )
      }

      const [openedReqCount, closedReqCount] = await Promise.all(querys);
      if (prevValue?.s) {
        if (!openedReqCount && !closedReqCount) {
          delete roles?.services?.[prevValue.s].customer;
          if (isEmpty(roles.services?.[prevValue.s])) delete roles.services[prevValue.s];
        } else if (!openedReqCount && !!closedReqCount) {
          roles.services[prevValue.s].customer.active = false;
        }
      }
      if (prevValue.w) {
        if (!openedReqCount && !closedReqCount) {
          delete roles.workingAreas?.[prevValue.w].customer;
          if (isEmpty(roles.workingAreas?.[prevValue.w])) delete roles.workingAreas[prevValue.w];
        } else {
          if (!openedReqCount && closedReqCount) {
            roles.workingAreas[prevValue.w].customer.active = false;
          }
        }
      }
    }
  }

  private getCustomerCount(customerId: string, query: { field: 'serviceId' | 'workareaId', value: any }, isClosed: boolean) {
    const where: Where[] = [['management.customerId', '==', customerId], [`management.${query.field}`, '==', query.value], ['isClosed', '==', isClosed]];
    return this.afsDB.getCounts(`organizations/${this.orgId}/requests`, () => where);
  }

  hasCustomerRole(role: UserRolesConfig) {
    return !!(keys(role.workingAreas).filter(key => role.workingAreas[key]?.customer?.active).length
      || keys(role.services).filter(key => role.services[key]?.customer?.active).length)
  }

  public getExecutorsForOrganization(noCache?: boolean): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    if (noCache) {
      return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
        return ref
          .where(`roles.isExecutor`, '==', true);
      }));
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        return ref
          .where(`roles.isExecutor`, '==', true);
      });
    }
  }

  public getExecutorsForWorkingArea(workingAreaId: string, noCache?: boolean): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;
    if (noCache) {
      return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
        return ref
          .where(`roles.workingAreas.${workingAreaId}.executor.active`, '==', true);
      }));
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        return ref
          .where(`roles.workingAreas.${workingAreaId}.executor.active`, '==', true);
      });
    }
  }

  public getExecutorsForService(serviceId: string, noCache?: boolean): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;
    if (noCache) {
      return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
        return ref
          .where(`roles.services.${serviceId}.executor.active`, '==', true);
      }));
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        return ref
          .where(`roles.services.${serviceId}.executor.active`, '==', true);
      });
    }
  }

  public getCoordinatorsForWorkingArea(workingAreaId: string, noCache?: boolean): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;
    if (noCache) {
      return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
        return ref
          .where(`roles.workingAreas.${workingAreaId}.coordinator.active`, '==', true);
      }));
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        return ref
          .where(`roles.workingAreas.${workingAreaId}.coordinator.active`, '==', true);
      });
    }
  }

  public getCoordinatorsForService(serviceId: string, noCache?: boolean): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    if (noCache) {
      return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
        return ref
          .where(`roles.services.${serviceId}.coordinator.active`, '==', true);
      }));
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        return ref
          .where(`roles.services.${serviceId}.coordinator.active`, '==', true);
      });
    }
  }

  public getProfessionalsForWorkingArea(workingAreaId: string, noCache?: boolean): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    if (noCache) {
      return from(this.afsDB.colWithIdsNoCache<User>(colRef, ref => {
        return ref
          .where(`roles.workingAreas.${workingAreaId}.professional.active`, '==', true);
      }));
    } else {
      return this.afsDB.colWithIds$(colRef, ref => {
        return ref
          .where(`roles.workingAreas.${workingAreaId}.professional.active`, '==', true);
      });
    }
  }

  public async removeAllUsersFromService(serviceId: string, workingAreaId: string): Promise<any> {
    const [executors, coordinators] = await Promise.all([
      this.getExecutorsForService(serviceId).pipe(take(1)).toPromise(),
      this.getCoordinatorsForService(serviceId).pipe(take(1)).toPromise()
    ]);

    const users = uniqBy(executors.concat(coordinators), 'id');

    const promises = users.map(async (user) => {

      await this.updateUserRoles(user, 'executor', workingAreaId, 'removeService', serviceId, false);

      return this.updateUser(user);
    });

    if (promises.length > 0) {
      await Promise.all(promises);
    }
  }

  public async removeAllUsersFromWorkingArea(workingAreaId: string): Promise<any> {
    const professionals = await this.getProfessionalsForWorkingArea(workingAreaId).pipe(take(1)).toPromise();

    const promises = professionals.map(async (user) => {
      return this.updateUserRoles(user, 'professional', workingAreaId, 'remove', undefined, true);
    });

    if (promises.length > 0) {
      await Promise.all(promises);
    }
  }

  public getExecutors(serviceId?: string, workingAreaId?: string): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return this.afsDB.colWithIds$(colRef, refv => {
      let ref = refv;

      if (!serviceId && !workingAreaId) {
        ref = ref.where('roles.isExecutor', '==', true); // org level
      }

      if (serviceId) {
        ref = ref.where(`roles.services.${serviceId}.executor.active`, '==', true);  // service level
      }

      if (workingAreaId) {
        ref = ref.where(`roles.workingAreas.${workingAreaId}.executor.active`, '==', true); // working area level
      }

      return ref;
    });
  }

  public getCoordinators(serviceId?: string, workingAreaId?: string): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return this.afsDB.colWithIds$(colRef, refv => {
      let ref = refv;

      if (!serviceId && !workingAreaId) {
        ref = ref.where('roles.isCoordinator', '==', true); // org level
      }

      if (serviceId) {
        ref = ref.where(`roles.services.${serviceId}.coordinator.active`, '==', true);  // service level
      }

      if (workingAreaId) {
        ref = ref.where(`roles.workingAreas.${workingAreaId}.coordinator.active`, '==', true); // working area level
      }

      return ref;
    });
  }

  public getProfessionals(workingAreaId?: string): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return this.afsDB.colWithIds$(colRef, refv => {
      let ref = refv;

      if (!workingAreaId) {
        ref = ref.where('roles.isProfessional', '==', true); // org level
      }

      if (workingAreaId) {
        ref = ref.where(`roles.workingAreas.${workingAreaId}.professional.active`, '==', true); // working area level
      }

      return ref;
    });
  }

  public async getCounts(type: 'users' | 'docs', id?: string, queryFn?: () => Where[]): Promise<number> {
    let ref: string;
    if (type === 'users') ref = `organizations/${this.orgId}/users`;
    else if (type === 'docs') ref = `organizations/${this.orgId}/users/${id}/docs`;

    return this.afsDB.getCounts(ref, queryFn);
  }

  public getFilteredUsers(userFilter: UserFilter): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return this.afsDB.colWithIds$(colRef, ref => {

      if (userFilter.houseNumberFilter) {
        ref = ref.where('address.number', '==', +userFilter.houseNumberFilter);
      }

      if (userFilter.postalCodeFilter) {
        ref = ref.where('address.postalcode', '==', userFilter.postalCodeFilter);
      }

      if (userFilter.membership && userFilter.membership !== '-1') {
        ref = ref.where('membership.membershipKind', '==', userFilter.membership)
      }

      if (userFilter.mailingList && userFilter.mailingList !== '-1') {
        ref = ref.where('mailinglistSubscriptions', 'array-contains', userFilter.mailingList)
      }

      if (userFilter.municipalityFilter) {
        ref = ref.where('area.municipality.code', '==', userFilter.municipalityFilter.code);
      }

      if (userFilter.districtFilter && userFilter.districtFilter !== '-1') {
        ref = ref.where('area.district.code', '==', userFilter.districtFilter)
      }

      if (userFilter.neighborhoodFilter && userFilter.neighborhoodFilter !== '-1') {
        ref = ref.where('area.neighbourhood.code', '==', userFilter.neighborhoodFilter)
      }

      if (userFilter.rolesFilter) {
        if (userFilter.rolesFilter === 'coord') {
          ref = ref.where(`roles.isCoordinator`, '==', true);
          if (userFilter.workingAreaFilter && (userFilter.workingAreaFilter !== '-1')) {
            ref = ref.where(`roles.workingAreas.${userFilter.workingAreaFilter}.coordinator.active`, '==', true);
          }
          if (userFilter.serviceFilter && (userFilter.serviceFilter !== '-1')) {
            ref = ref.where(`roles.services.${userFilter.serviceFilter}.coordinator.active`, '==', true);
            // ref = ref.where(`roles.services.${userFilter.serviceFilter}.coordinator.disabled`, '==', userFilter.statusFilter);
          }
        } else if (userFilter.rolesFilter === 'exec') {
          ref = ref.where(`roles.isExecutor`, '==', true);
          if (userFilter.workingAreaFilter && (userFilter.workingAreaFilter !== '-1')) {
            ref = ref.where(`roles.workingAreas.${userFilter.workingAreaFilter}.executor.active`, '==', true);
          }
          if (userFilter.serviceFilter && (userFilter.serviceFilter !== '-1')) {
            ref = ref.where(`roles.services.${userFilter.serviceFilter}.executor.active`, '==', true);
            ref = ref.where(`roles.services.${userFilter.serviceFilter}.executor.disabled`, '==', userFilter.statusFilter);
          }
        } else if (userFilter.rolesFilter === 'prof') {
          ref = ref.where(`roles.isProfessional`, '==', true);
          if (userFilter.workingAreaFilter && (userFilter.workingAreaFilter !== '-1')) {
            ref = ref.where(`roles.workingAreas.${userFilter.workingAreaFilter}.professional.active`, '==', true);
          }
        } else if (userFilter.rolesFilter === 'cus') {
          ref = ref.where(`roles.isCustomer`, '==', true);
          if (userFilter.workingAreaFilter && (userFilter.workingAreaFilter !== '-1')) {
            ref = ref.where(`roles.workingAreas.${userFilter.workingAreaFilter}.customer.active`, '==', true);
          }
          if (userFilter.serviceFilter && (userFilter.serviceFilter !== '-1')) {
            ref = ref.where(`roles.services.${userFilter.serviceFilter}.customer.active`, '==', !userFilter.statusFilter);
          }
        }
      }
      return ref;
    }, true);
  }

  public getUsersWithPaginationNOrderBy(orderBy?: string, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    return this.afsDB.colWithIds$(colRef, ref => {
      switch (orderBy) {
        case 'last-name':
          ref = ref.orderBy('lastname', 'asc');
          break;
        case 'first-name':
          ref = ref.orderBy('firstname', 'asc');
          break;
        case 'street':
          ref = ref.orderBy('address.street', 'asc');
          break;
        default:
          ref = ref.orderBy('lastname', 'asc');
          break;
      }
      if (lastOrFirstRef) {
        if (isReloadedContext) {
          ref = ref.startAt(lastOrFirstRef);
          ref = ref.limit(limit || 30);
        } else {
          if (isForwardNavigation) {
            ref = ref.startAfter(lastOrFirstRef);
            ref = ref.limit(limit || 30);
          } else {
            ref = ref.endBefore(lastOrFirstRef);
            ref = ref.limitToLast(limit || 30);
          }
        }
      } else {
        ref = ref.limit(limit || 30);
      }
      return ref;
    }, true);
  }

  public getUserServices(ids: string[]) {
    const ref = `organizations/${this.orgId}/services`;
    const where: Where[] = [];
    return this.afsDB.ColWithIdsNoCacheNew<Service>(ref, () => {
      where.push(['id', 'in', ids]);
      return where;
    });
  }

  public getUserWorkingAreas<T = any>(id: string) {
    const ref = `organizations/${this.orgId}/workingAreas`;
    // return this.afsDB.docWithIdNew$(ref)
    const where: Where[] = [];
    return this.afsDB.ColWithIdsNoCacheNew<T>(ref, () => {
      where.push(['id', '==', id]);
      return where;
    });
  }

  public searchUsers(searchWord?: string, limit?: number): Observable<User[]> {
    const colRef = `/organizations/${this.orgId}/users`;

    if (searchWord) {
      return new Observable((observer: Observer<User[]>) => {
        const where = [['active', '==', true]];

        this.searchService.searchCollectionIndex({
          searchText: searchWord,
          ref: colRef,
          limit: limit || 50,
          where
        }).then((users: User[]) => {
          observer.next(users);
        });
      });
    }
  }

  public async saveUserRole(user: User, role: UserActionRoleTypes, workingArea: WorkingArea, action: UserRoleActionTypes, workingAreaId?: string, service?: Service, save?: boolean, institutionId?: string, prevValue?: { w?: string; s?: string, type?: 'open' | 'close' }) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const res = await this.updateUserRoles(user, role, workingAreaId || workingArea.id, action, service?.id, save, institutionId, prevValue);
        if (res) {
          if (action == "remove" && role == "executor") {
            this.removeExecutorSideEffects(service.id, this.orgId, user.id).pipe(take(1)).toPromise()
              .then(console.log).catch(console.log);

            delete service.excecutors[user.id];
            if (service.usersCanSeeAllRequests[user.id]) {
              delete service.usersCanSeeAllRequests[user.id];
            }
          }

          if (role === 'professional') {
            await this.workingAreaService.updateWorkingArea(workingArea);
          }
          if (role === 'coordinator' || role === 'executor') {
            await this.orgServiceService.updateService(service);
          }
          if (role === 'professional' || role === 'executor') {
            await this.handleUserExpirySync(user.id);
          }
          resolve(`${role}_${action}ed`);
        }
      } catch (error) {
        console.log(error)
        reject(`Unable to_${action}_user_role`);
      }
    });
  }

  public getOrgId() {
    return this.orgId;
  }

  public getInstitutionContactPersons(institutionId: string) {
    const colRef = `/organizations/${this.orgId}/users`;
    return this.afsDB.colWithIdsNew$<User>(colRef, () => {
      return [[`roles.institutions.${institutionId}.institutionId`, '==', institutionId]];
    })
  }

  public getInstitutionContactPersonById(institutionId: string, contactPersonId: string): Observable<User> {
    const colRef = `/organizations/${this.orgId}/users`;
    return this.afsDB.colWithIdsNew$<User>(colRef, () => {
      return [
        [`roles.institutions.${institutionId}.institutionId`, '==', institutionId],
        ['id', '==', contactPersonId]
      ];
    }, { limit: 1 }).pipe(map(cp => cp[0]))
  }

  public removeExecutorSideEffects(serviceId: string, orgId: string, executorId: string) {
    return this.apiService.post('executor/team/remove', { serviceId, orgId, executorId });
  }

  public getAfasUserWithEmail(orgId: string, email: string, title: ApiCallType) {
    return this.apiService.post('afas/user/get', { orgId, email, title }).pipe(take(1)).toPromise() as unknown as Promise<{ rows: object[]; skip: number; take: number }>;
  }

  public createAfasUser(orgId: string, user: any, title: ApiCallType) {
    return this.apiService.post('afas/user/create', { orgId, user, title }).pipe(take(1)).toPromise() as unknown as Promise<{ results: { KnPerson: { BcId: string, BcCo: string } } }>;
  }

  public updateAfasUser(orgId: string, update: any, title: ApiCallType) {
    return this.apiService.put('afas/user/update', { orgId, update, title }).pipe(take(1)).toPromise() as unknown as Promise<{ results: { KnPerson: { BcId: string, BcCo: string } } }>;
  }

  public async afasConnect(orgId: string, user: User) {
    // Check if user already exists in AFAS
    try {
      const res = await this.getAfasUserWithEmail(orgId, user.email, 'get user by email');
      if (res.rows.length) {
        const existingUser = res.rows[0];
        user.afasDetails = user.afasDetails || {} as AfasDetails;
        user.afasDetails.KnPerson = {
          BcCo: (existingUser as any).BcCo,
          BcId: (existingUser as any).BcId
        };
        user.afasDetails.connected = true;
        return user;
      } else {
        // Create new AFAS user
        const createRes = await this.createAfasUser(orgId, JSON.parse(JSON.stringify(this.mapAfasUser(user))), 'create user');
        const knPerson = createRes.results.KnPerson;
        user.afasDetails = user.afasDetails || {} as AfasDetails;
        user.afasDetails.KnPerson = {
          BcCo: knPerson.BcCo,
          BcId: knPerson.BcId
        };
        user.afasDetails.connected = true;
        return user;
      }
    } catch (error) {
      throw error;
    }
  }

  private mapAfasUser(user: User): object {
    user = cloneDeep(user);
    return {
      "KnPerson": {
        "Element": {
          "@BcId": 0,
          "Fields": {
            "PadAdr": true,
            "AutoNum": true,
            "MatchPer": "0",
            "SeNm": user.firstname.substring(0, 10),
            "CaNm": user.firstname,
            "FiNm": user.firstname,
            "In": "",
            "LaNm": user.lastname,
            "ViGe": (user.gender || 'U').charAt(0).toUpperCase(),
            "DaBi": user.birthday,
            "SoSe": user.personalNumber,
            "EmAd": user.email,
            "U5076F6D813904F3E99040BAD1E07F9F3": user.afasDetails?.isAfasExecutor || false
          },
          "Objects": [
            {
              "KnBankAccount": {
                "Element": {
                  "Fields": {
                    "CoId": "NL",
                    "Iban": user.iban || ''
                  }
                }
              }
            },
            {
              "KnBasicAddressAdr": {
                "Element": {
                  "Fields": {
                    "CoId": "NL",
                    "PbAd": false,
                    "StAd": "",
                    "Ad": user.address.street,
                    "HmNr": user.address.number,
                    "HmAd": user.address.letter,
                    "ZpCd": user.address.postalcode,
                    "Rs": user.address.city,
                    "ResZip": false
                  }
                }
              }
            }
          ]
        }
      }
    };
  }

  public async updateAfasUserRoleAsExecutor(orgId: string, user: User, title: ApiCallType) {
    const isExecutor = user.afasDetails.isAfasExecutor;
    const update = {
      "KnPerson": {
        "Element": {
          "Fields": {
            "MatchPer": "0",
            "BcCo": user.afasDetails.KnPerson.BcCo,
            "U5076F6D813904F3E99040BAD1E07F9F3": isExecutor
          }
        }
      }
    }
    try {
      await this.updateAfasUser(orgId, update, title);
      user.afasDetails.isAfasExecutor = isExecutor;
      return user;
    } catch (error) {
      this.userPromptsService.showToast('Failed to update AFAS user role as executor.');
      console.log(error)
      throw error;
    }
  }

  public getAfasUsers() {
    return this.afsDB.colWithIdsNew$(`organizations/${this.orgId}/users`, () => [['afasDetails.connected', '==', true]])
  }
}
