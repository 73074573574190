<div class="main">
    <div fxLayout="row" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline">
            <mat-label>{{labels.search}}</mat-label>
            <input matInput type="text" [(ngModel)]="requestTabFilters.textFilter" (keydown.enter)="handleFilterSubmit()">
            @if (requestTabFilters.textFilter) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>
    
        <button *ngIf="userRole.roleType !== 1 && !currentRequestId" mat-raised-button color="primary"
            (click)="createRequest()">
            {{labels.add_request}}
        </button>
    </div>
    @if (isLoading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
    @if (requests && currentUserConnectedServices && !isLoading) {
    <div>
        @if (tableCfg?.data) {
        <mat-accordion>
            <mat-expansion-panel hideToggle *ngFor="let row of tableCfg.data"
                [disabled]="row.originalRequest.id === currentRequestId"
                (opened)="getRequestCreatorName(row.originalRequest.log.createdBy)">

                <mat-expansion-panel-header [ngClass]="[row._rowClass ? row._rowClass : '']">
                    <mat-panel-description style="width: 50%; color: black;">
                        {{row.title}}
                    </mat-panel-description>
                    <mat-panel-description style="width: 25%; color: black;">
                        {{row.service}}
                    </mat-panel-description>
                    <mat-panel-description style="width: 25%; color: black;">
                        {{row.executorFullName}}
                    </mat-panel-description>

                </mat-expansion-panel-header>
                <div fxLayout="column" class="settings_content" style="margin-left: 15px;">
                    <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 20px;">
                        <div fxFlex="stretch" fxLayout="column">
                            <div class="text-primary">{{labels.created_at}}</div>
                            <div>{{toDate(row.originalRequest.log.createdAt)}}</div>
                        </div>
                        <div fxFlex="stretch" fxLayout="column">
                            <div class="text-primary">{{labels.start_date}}</div>
                            <div>{{row.originalRequest?.startDate}}</div>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px">
                        <div fxFlex="stretch" fxLayout="column">
                            <div class="text-primary">{{labels.created_by}}</div>
                            <div>{{creatorName}}</div>
                        </div>
                        <div fxFlex="stretch" fxLayout="column">
                            <div class="text-primary">{{labels.end_date}}</div>
                            <div>{{row.originalRequest?.endDate}}</div>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
                        <div fxFlex="stretch" fxLayout="column"></div>
                        <div fxLayout="column">
                            <button class="text-primary" style="width: max-content;" mat-button
                                [disabled]="!currentUserConnectedServices.includes(row.originalRequest.management.serviceId)"
                                (click)="handleRowSelect(row)">{{labels.go_to_request}}</button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        }

        @if (!isLoading && !tableCfg.data.length) {
        <div class="py-4">
            <app-empty-list
                [title]="tableCfg.data.length ? labels.empty_requests_title : labels.empty_requests_title_alt"
                [description]="tableCfg.data.length ? labels.empty_requests_desc : labels.empty_requests_desc_alt">
            </app-empty-list>
        </div>
        }
    </div>
    }
    <div style="margin-top: 16px;">
        <mat-paginator (page)="onPaginationChange($event)" [length]="paginationOptions.totalSize"
            [pageSize]="paginationOptions.dataPerPage" [pageIndex]="paginationOptions.pageIndex"
            [pageSizeOptions]="[5, 10, 30, 50]" aria-label="Select page">
        </mat-paginator>
    </div>
</div>